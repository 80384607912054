.loading {
   position: fixed;
   top: 50%;
   left: 50%;
   margin: -14px 0 0 -42px;
   padding: 10px;
   background: rgba(119, 119, 119, 0.9);
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;

   /* -webkit-box-shadow: inset 0 0 5px #000, 0 1px 1px rgba(255, 255, 255, 0.1);
   -moz-box-shadow: inset 0 0 5px #000, 0 1px 1px rgba(255, 255, 255, 0.1);
   -ms-box-shadow: inset 0 0 5px #000, 0 1px 1px rgba(255, 255, 255, 0.1);
   -o-box-shadow: inset 0 0 5px #000, 0 1px 1px rgba(255, 255, 255, 0.1);
   box-shadow: inset 0 0 5px #000, 0 1px 1px rgba(255, 255, 255, 0.1); */
}

.loading-dot {
   float: left;
   width: 8px;
   height: 8px;
   margin: 0 4px;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   border-radius: 50%;

   opacity: 0;

   -webkit-box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%),
      inset 0 -1px 0 hsl(0deg 0% 100% / 10%),
      inset 1px 0 0 hsl(0deg 0% 100% / 5%),
      inset -1px 0 0 hsl(0deg 0% 100% / 5%), 0 4px 8px rgb(0 0 0 / 45%);
   -moz-box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%),
      inset 0 -1px 0 hsl(0deg 0% 100% / 10%),
      inset 1px 0 0 hsl(0deg 0% 100% / 5%),
      inset -1px 0 0 hsl(0deg 0% 100% / 5%), 0 4px 8px rgb(0 0 0 / 45%);
   -ms-box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%),
      inset 0 -1px 0 hsl(0deg 0% 100% / 10%),
      inset 1px 0 0 hsl(0deg 0% 100% / 5%),
      inset -1px 0 0 hsl(0deg 0% 100% / 5%), 0 4px 8px rgb(0 0 0 / 45%);
   -o-box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%),
      inset 0 -1px 0 hsl(0deg 0% 100% / 10%),
      inset 1px 0 0 hsl(0deg 0% 100% / 5%),
      inset -1px 0 0 hsl(0deg 0% 100% / 5%), 0 4px 8px rgb(0 0 0 / 45%);
   box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%),
      inset 0 -1px 0 hsl(0deg 0% 100% / 10%),
      inset 1px 0 0 hsl(0deg 0% 100% / 5%),
      inset -1px 0 0 hsl(0deg 0% 100% / 5%), 0 4px 8px rgb(0 0 0 / 45%);

   -webkit-animation: loadingFade 1s infinite;
   -moz-animation: loadingFade 1s infinite;
   animation: loadingFade 1s infinite;
}

.ldc-green {
   background: #6bc479;
}

.ldc-blue {
   background: #69b0ff;
}

.loading-dot:nth-child(1) {
   -webkit-animation-delay: 0s;
   -moz-animation-delay: 0s;
   animation-delay: 0s;
}

.loading-dot:nth-child(2) {
   -webkit-animation-delay: 0.1s;
   -moz-animation-delay: 0.1s;
   animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
   -webkit-animation-delay: 0.2s;
   -moz-animation-delay: 0.2s;
   animation-delay: 0.2s;
}

.loading-dot:nth-child(4) {
   -webkit-animation-delay: 0.3s;
   -moz-animation-delay: 0.3s;
   animation-delay: 0.3s;
}

@-webkit-keyframes loadingFade {
   0% {
      opacity: 0;
   }
   50% {
      opacity: 0.8;
   }
   100% {
      opacity: 0;
   }
}

@-moz-keyframes loadingFade {
   0% {
      opacity: 0;
   }
   50% {
      opacity: 0.8;
   }
   100% {
      opacity: 0;
   }
}

@keyframes loadingFade {
   0% {
      opacity: 0;
   }
   50% {
      opacity: 0.8;
   }
   100% {
      opacity: 0;
   }
}
